import React, { useCallback, useEffect, useState } from 'react';
import russiaHeader from './resources/images/russia-header.png';
import graph from './resources/images/graph.png';
import './App.css';
import moment from 'moment';
import { PeriodType } from './mytype/period';
import { services } from './services/services';
import { utils } from './utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'

const announceDate = moment().format('YYYY-MM-DD 22:51:00'); // 22.10 close

const App = () => {

  const [russiaStat, setRussiaStat] = useState<PeriodType[]>([]);
  const [russiaCurrent, setRussiaCurrent] = useState<PeriodType | null>(null);
  const [positiveOrNegative, setPositiveOrNegative] = useState("+");
  const [positiveOrNegativeChange, setPositiveOrNegativeChange] = useState("+");
  const [positiveOrNegativePercent, setPositiveOrNegativePercent] = useState("+");
  const [upNumber, setUpNumber] = useState(63000.00);
  const [downNumber, setDownNumber] = useState(0.00);
  const [changedPercent, setChangedPercent] = useState(0.00);

  const getRussiaStatData = useCallback(async () => {
    const response = await services.getStatistics(23);
    setRussiaStat(response.data);
  }, []);

  useEffect(() => {

    getRussiaStatData();
    russiaData();

    // every 5 min
    triggerData();
    const interval = setInterval(() => {
      triggerData();
    }, 30000);

    const intervalForShow = setInterval(() => {
      if (moment().toDate().getTime() === moment(announceDate).toDate().getTime()) {
        triggerData();
      }
    }, (60 * 1000));

    return () => {
      clearInterval(interval);
      clearInterval(intervalForShow)
    }
  }, [getRussiaStatData]);

  const triggerData = async () => {

    let positiveOrNegativeNumber = Math.round(Math.random());
    if (positiveOrNegativeNumber) positiveOrNegativeNumber = 1.00;
    else positiveOrNegativeNumber = -1.00;

    let positiveOrNegativeNumberLast = Math.round(Math.random());
    if (positiveOrNegativeNumberLast) positiveOrNegativeNumberLast = 1.00;
    else positiveOrNegativeNumberLast = -1.00;

    let positiveOrNegativeNumberChange = Math.round(Math.random());
    if (positiveOrNegativeNumberChange) positiveOrNegativeNumberChange = 1.00;
    else positiveOrNegativeNumberChange = -1.00;

    let positiveOrNegativeNumberPercent = Math.round(Math.random());
    if (positiveOrNegativeNumberPercent) positiveOrNegativeNumberPercent = 1.00;
    else positiveOrNegativeNumberPercent = -1.00;

    // ตอนนี้ช่วงเที่ยงคืนถึง 8.00 น.
    if (
      moment().toDate().getTime() >= moment(moment().format('YYYY-MM-DD 00:00:00')).toDate().getTime() &&
      moment().toDate().getTime() < moment(moment().format('YYYY-MM-DD 08:00:00')).toDate().getTime()
    ) {
      if (!russiaCurrent) {
        const currentEngland = await services.getCurrentPeriods(23);
        const data: PeriodType = currentEngland.data;

        if (currentEngland.data.showResult === "true") {
          const num = parseInt(upNumber.toString().substring(0, 4) + data.up3) * 0.01;

          const precisionDown = 100; // 2 decimals
          const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

          const precisionChangedPercent = 100; // 2 decimals
          const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

          setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()) * (positiveOrNegativeNumber));
          setChangedPercent(numChangedPercent * (positiveOrNegativeNumber));
          setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
          // setVolumn10Mil(numVolumn10Bil);
          setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
          setPositiveOrNegativeChange(positiveOrNegativeNumberChange === 1.00 ? "+" : "-");
          setPositiveOrNegativePercent(positiveOrNegativeNumberPercent === 1.00 ? "+" : "-");
          return setRussiaCurrent(currentEngland.data);
        } else {
          const response = await services.getStatistics(23);
          const data: PeriodType = response.data[0];

          const num = parseInt(upNumber.toString().substring(0, 4) + data.up3) * 0.01;

          const precisionDown = 100; // 2 decimals
          const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

          const precisionChangedPercent = 100; // 2 decimals
          const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

          setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()) * (positiveOrNegativeNumber));
          setChangedPercent(numChangedPercent * (positiveOrNegativeNumber));
          setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
          // setVolumn10Mil(numVolumn10Bil);
          setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
          setPositiveOrNegativeChange(positiveOrNegativeNumberChange === 1.00 ? "+" : "-");
          setPositiveOrNegativePercent(positiveOrNegativeNumberPercent === 1.00 ? "+" : "-");
          return setRussiaCurrent(currentEngland.data);
        }
      }
    }

    // ตอนนี้ช่วง 8.00 ถึง 23.05
    if (
      moment().toDate().getTime() > moment(moment().format('YYYY-MM-DD 08:00:00')).toDate().getTime() &&
      moment().toDate().getTime() < moment(announceDate).toDate().getTime()
    ) {
      return randomNumber();
    }

    // ตอนนี้ช่วง 23.05 ถึง 23.59
    if (
      moment().toDate().getTime() > moment(announceDate).toDate().getTime() &&
      moment().toDate().getTime() < moment(moment().format('YYYY-MM-DD 23:59:59')).toDate().getTime()
    ) {
      if (!russiaCurrent) {
        const currentEngland = await services.getCurrentPeriods(23);
        const data: PeriodType = currentEngland.data;

        const num = parseInt(upNumber.toString().substring(0, 4) + data.up3) * 0.01;

        const precisionDown = 100; // 2 decimals
        const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

        const precisionChangedPercent = 100; // 2 decimals
        const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

        setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()) * (positiveOrNegativeNumber));
        setChangedPercent(numChangedPercent * (positiveOrNegativeNumber));
        setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
        // setVolumn10Mil(numVolumn10Bil);
        setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
        setPositiveOrNegativeChange(positiveOrNegativeNumberChange === 1.00 ? "+" : "-");
        setPositiveOrNegativePercent(positiveOrNegativeNumberPercent === 1.00 ? "+" : "-");
        return setRussiaCurrent(currentEngland.data);
      }
    }

  }

  const randomNumber = () => {

    let positiveOrNegativeNumber = Math.round(Math.random());
    if (positiveOrNegativeNumber) positiveOrNegativeNumber = 1.00;
    else positiveOrNegativeNumber = -1.00;

    let positiveOrNegativeNumberLast = Math.round(Math.random());
    if (positiveOrNegativeNumberLast) positiveOrNegativeNumberLast = 1.00;
    else positiveOrNegativeNumberLast = -1.00;

    let positiveOrNegativeNumberChange = Math.round(Math.random());
    if (positiveOrNegativeNumberChange) positiveOrNegativeNumberChange = 1.00;
    else positiveOrNegativeNumberChange = -1.00;

    let positiveOrNegativeNumberPercent = Math.round(Math.random());
    if (positiveOrNegativeNumberPercent) positiveOrNegativeNumberPercent = 1.00;
    else positiveOrNegativeNumberPercent = -1.00;

    const precisionUp = 100; // 2 decimals
    const numUp = Math.floor(Math.random() * (100 * precisionUp - 1 * precisionUp) + 1 * precisionUp) / (1 * precisionUp);

    const precisionDown = 100; // 2 decimals
    const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

    const precisionChangedPercent = 100; // 2 decimals
    const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

    // const precisionVolumn10Bil = 100; // 2 decimals
    // const numVolumn10Bil = Math.floor(Math.random() * (100 * precisionVolumn10Bil - 1 * precisionVolumn10Bil) + 1 * precisionVolumn10Bil) / (1 * precisionVolumn10Bil);

    // const precisionVolumn100Bil = 100; // 2 decimals
    // const numVolumn100Bil = Math.floor(Math.random() * (10000 * precisionVolumn100Bil - 1 * precisionVolumn100Bil) + 1 * precisionVolumn100Bil) / (1 * precisionVolumn100Bil);


    setUpNumber(upNumber + (numUp * positiveOrNegativeNumber));
    setDownNumber(numDown * positiveOrNegativeNumberChange);
    setChangedPercent(numChangedPercent * positiveOrNegativeNumberPercent);
    // setVolumn10Mil(numVolumn10Bil);
    // setVolumn100Bil(numVolumn100Bil);
    setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
    setPositiveOrNegativeChange(positiveOrNegativeNumberChange === 1.00 ? "+" : "-");
    setPositiveOrNegativePercent(positiveOrNegativeNumberPercent === 1.00 ? "+" : "-");
  }

  const renderStat = () => {
    if (russiaStat.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>No Statistics</td>
        </tr>
      )
    }

    const stat = [];

    for (let i = 0; i < russiaStat.length; i++) {
      const element = (
        <tr>
          <td>{moment(russiaStat[i].dateAnnounce).format("DD/MM/YYYY")}</td>
          <td>{russiaStat[i].up3}</td>
          <td>{russiaStat[i].down2}</td>
        </tr>
      );

      stat.push(element);
    }

    return stat;
  }

  const russiaData = async () => {
    // ตอนนี้ช่วงเที่ยงคืนถึง 8.00 น.
    if (
      moment().toDate().getTime() >= moment(moment().format('YYYY-MM-DD 00:00:00')).toDate().getTime() &&
      moment().toDate().getTime() < moment(moment().format('YYYY-MM-DD 08:00:00')).toDate().getTime()
    ) {
      if (!russiaCurrent) {
        const current = await services.getCurrentPeriods(23);

        if (current.data.showResult === "true") {
          return setRussiaCurrent(current.data);
        } else {
          const response = await services.getStatistics(23);
          return setRussiaCurrent(response.data[0]);
        }
      }
    }

    // ตอนนี้ช่วง 8.00 ถึง 23.05
    if (
      moment().toDate().getTime() > moment(moment().format('YYYY-MM-DD 08:00:00')).toDate().getTime() &&
      moment().toDate().getTime() < moment(announceDate).toDate().getTime()
    ) {
      return setRussiaCurrent(null);
    }

    // ตอนนี้ช่วง 23.05 ถึง 23.59
    if (
      moment().toDate().getTime() > moment(announceDate).toDate().getTime() &&
      moment().toDate().getTime() < moment(moment().format('YYYY-MM-DD 23:59:59')).toDate().getTime()
    ) {
      if (!russiaCurrent) {
        const current = await services.getCurrentPeriods(23);
        return setRussiaCurrent(current.data);
      }
    }
  }

  const russiaClosed = () => {
    return (
      <>
        <td>Evening Closed</td>
        <td>Up</td>
        <td>{russiaCurrent?.up3}</td>
        <td>Down</td>
        <td>{russiaCurrent?.down2}</td>
      </>
    )
  }

  const announceClose = () => {
    if (
      moment().toDate().getTime() >= moment(announceDate).toDate().getTime()
      && moment().toDate().getTime() < moment(announceDate).toDate().getTime()
    ) {
      return "*Closed"
    }

    return "";
  }

  return (
    <div className="container p-0">
      <div className="twindex">
        <div className="header">
          <img src={russiaHeader} style={{ width: '100%', padding: 0 }} />
        </div>

        <div className="content">
          <div className="align-baseline d-flex p-lg-2 chart-info">
            <span style={{ fontSize: 36 }}>{(upNumber).toFixed(2)}</span> 
            <span className={`pl-2 pr-2 d ${positiveOrNegative === '+' ? 'up' : 'down'}`} style={{ fontSize: 20 }}>{positiveOrNegative === "+" ? '+' : '-'}{Math.abs(downNumber).toFixed(2)} ({positiveOrNegative === "+" ? '+' : '-'}{Math.abs(changedPercent).toFixed(2)}%)</span> 
            {positiveOrNegative === "+" ? <FontAwesomeIcon icon={faArrowAltCircleUp} color='green' /> : <FontAwesomeIcon icon={faArrowAltCircleDown} color='red' />}
          </div>
          <div className="align-baseline d-flex pl-2" style={{ fontSize: 24 }}>
            {moment().format("MMM/DD/YYYY")} {announceClose()}
          </div>
          <div className="chart">
            <img src={graph} height={380} width="100%" />
          </div>
          <div className="today-result pt-2">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  {russiaClosed()}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="history">
        <div className="history-content">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th rowSpan={2}>Date</th>
                <th colSpan={2}>Evening Closed</th>
              </tr>
              <tr>
                <th>Up</th>
                <th>Down</th>
              </tr>
            </thead>
            <tbody>
              {renderStat()}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
}

export default App;
